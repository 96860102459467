<template>
    <div class="cd-form-group mx-3">
        <div class="mb-base flex justify-between items-center flex-wrap">
            <h4>{{$t('dietmgmt.dietmgmt')}}</h4>
            <div class="flex">
                <!-- 搜尋select -->
                <el-select class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4" popper-class="cd-appt-popper" v-model="eid">
                    <el-option
                        v-for="item in enterpriseList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="query"></vs-input>
            </div>
        </div>
        <div class="mb-base">
            <div v-for="(data,index) in list" :key="index">
                <vs-row class="flex">
                    <!-- 基本資料 -->
                    <vs-col class="px-4 w-48" style="margin-top: -1em !important;" vs-lg="3" vs-sm="6" vs-xs="12">
                        <span class="text-lg mx-2 cursor-pointer" @click="viewPersonalData(data)"             
                        style="color: #003f8e; solid #003f8e;font-size: 1.2em !important;line-height: 3em;white-space: nowrap;">{{data.name}}</span><br>
                        <span>{{$t('age')+'：'}}{{age(data.info[0].birthday)}}</span><br>
                        <span>{{$t('sex')+'：'}}{{getSex(data.info[0].sex)}}</span><br>
                        <span>{{$t('Height')+'：'}}{{getValue(data.info[0].height)}}</span><br>
                        <span>{{$t('Weight')+'：'}}{{getValue(data.info[0].weight)}}</span><br>
                        <span>BMI：{{getValue(data.info[0].BMI)}}</span><br>
                        <span>BMR(kcal)：{{getValue(data.BMR)}}</span><br>
                    </vs-col>

                    <vs-col class=" w-auto text-center" style="margin-top:2.5em !important;">
                        <div class="flex w-auto" v-for="(item,index) in data.data" :key="index">
                            {{ data.data[0].date }}
                            <!-- 第幾餐 -->
                            <div class="w-24">
                                <span v-if="item.first_meal_time">{{$t('dietmgmt.FirstMeal')  }}</span><br>
                                <span v-if="item.second_meal_time">{{$t('dietmgmt.SecondMeal')  }}</span><br>
                                <span v-if="item.third_meal_time">{{$t('dietmgmt.ThirdMeal')  }}</span><br>
                                <span v-if="item.fourth_meal_time">{{$t('dietmgmt.ForthMeal')  }}</span><br>
                                <span v-if="item.fifth_meal_time">{{$t('dietmgmt.FifthMeal')  }}</span><br>
                                <span v-if="item.sixth_meal_time">{{$t('dietmgmt.SixthMeal')  }}</span><br>
                            </div>
                            <!-- 用餐時間 -->
                            <div class="w-24">
                                <span v-if="item.first_meal_time">{{item.first_meal_time}}</span><br>
                                <span v-if="item.second_meal_time"  >{{item.second_meal_time}}</span><br>
                                <span v-if="item.third_meal_time"  >{{item.third_meal_time}}</span><br>
                                <span v-if="item.fourth_meal_time"  >{{item.fourth_meal_time}}</span><br>
                                <span v-if="item.fifth_meal_time"  >{{item.fifth_meal_time}}</span><br>
                                <span v-if="item.sixth_meal_time"  >{{item.sixth_meal_time}}</span><br>
                            </div>
                            <!-- 分析狀態 -->
                            <div class="w-24">
                                <span class="cursor-pointer hover:underline hover:font-semibold" v-if="data.dietDoctor"  @click="openPopup(data.mid,item.date,0)">{{ $t('dietmgmt.Analyzed') }}</span>
                                <span class="cursor-pointer hover:underline hover:font-semibold text-danger"  v-else-if="item.first_meal_time||item.second_meal_time||item.third_meal_time||item.fourth_meal_time||item.fifth_meal_time||item.sixth_meal_time"  @click="openPopup(data.mid,item.date,0)"> {{ $t('dietmgmt.Analyze') }}</span>
                                <span v-else >{{ $t('dietmgmt.noData')}}</span>
                            </div>
                        </div>
                    </vs-col>
                    <!-- 右方按鈕 -->
                    <vs-col class="px-4 w-48" style="margin-top:2.5em !important;" vs-lg="3" vs-sm="6" vs-xs="12">
                        <div class="flex justify-center">
                            <!-- <call :selectedPid="data.pid" :selectedPname="data.name" :selectdid="'patient'" :partnerid="partner_id" :interval="0" :finish="0"></call> -->
                            <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                                    <vs-button class="" type="flat" icon-pack="feather" icon="icon-more-vertical"></vs-button>
                                    <vs-dropdown-menu>
                                        <div class="flex whitespace-no-wrap p-2" style="min-width: 11rem">
                                            <ul class="w-full">
                                                <li class="flex p-2" :class=" 'cursor-pointer hover:bg-primary hover:text-white'" @click="showChatPopup(data.mid, data.name)">
                                                    <span>{{ $t('components.msg.msg')  }}</span>
                                                </li>
                                                <li class="flex p-2" :class=" 'cursor-pointer hover:bg-primary hover:text-white'">
                                                    <span :href="getPhone(data.info[0].mobile)" v-if="data.info[0].mobile">{{ $t('dietmgmt.call') }}{{data.info[0].mobile}}</span>
                                                </li>
                                                <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="openProductPopup(data.pid,0,data.name)">
                                                        <span>{{$t('emr.appt.Recommend') }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </vs-col>
                </vs-row>
                <vs-divider />
            </div>
            <!-- 查無資料 -->
            <div class="text-center" v-if="listTotal==0">{{ $t('apptHistory.noData') }}</div>
        </div>
        <!-- 分頁 -->
        <div class="flex justify-center" v-if="list">
            <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="total" v-model="memberPage"></vs-pagination>
        </div>
        <diet-popup :dietPopupActive="dietPopupActive" :payload="payload" @closePopup="closePopup"></diet-popup>
        <!-- 訊息視窗 -->
        <send-chat :chatPopup="openChatPopup" :smid="parseInt(midChat)" :sname="nameChat" @closeChat="closeChatPopup"></send-chat>
        <!-- 推薦商品 -->
        <product-popup :productPopupActive="productPopupActive" @closePopup="productClose" :pid="recommandPid" :sn_id="recommsnid" :pname="recommpname"></product-popup>
    </div>
</template>
<script>
import { fetchDietMember } from '@/api/user'
import DietPopup from '../components/dietPopup.vue';
import sendChat from '@/components/sendChat'
// import call from '@/components/call'
import productPopup from '@/components/productPopup.vue'
export default {
    data() {
        return {
            eid: null,
            list: null,
            listTotal: null,
            enterpriseList: null,
            query: null,
            dietPopupActive: false,
            openChatPopup: false,
            payload: {},
            memberPage: 1,
            memberLimit: 30,
            logsLimit: null,
            logsLimitList: [
                {
                    name: '今日',
                    ref: 'today',
                    days: 1,
                },
                {
                    name: '三日',
                    ref: 'threeDays',
                    days: 3,
                },
                {
                    name: '七日',
                    ref: 'sevenDays',
                    days: 7,
                }
            ],
            midChat: 0,
            nameChat: '',
            recommandPid:0,
            recommsnid:0,
            recommpname:'',
            allowshopstatus:'',
            productPopupActive:false
        }
    },
    components: {
        DietPopup,
        // call,
        sendChat,
        productPopup
    },
    watch: {
        logsLimit(){
            this.eid ? this.fetchDietMember(this.eid) : this.fetchDietMember();
        },
        eid(){
            if(this.memberPage != 1){
                this.memberPage = 1
            }else{
                this.eid ? this.fetchDietMember(this.eid) : this.fetchDietMember();
            }
        },
        memberPage(n, o){
            this.eid ? this.fetchDietMember(this.eid) : this.fetchDietMember();
            window.scrollTo(0,0);
        },
        query(){
            if(this.memberPage != 1){
                this.memberPage = 1
            }else{
                this.eid ? this.fetchDietMember(this.eid) : this.fetchDietMember();
            }
        }
    },
    mounted(){
        // this.$refs.today[0].click()
        this.eid ? this.fetchDietMember(this.eid) : this.fetchDietMember();
        this.allowshopstatus = localStorage.getItem('cart_token')
    },
    computed: {
        partner_id() {
            return this.$store.getters.partner
        },
        tableList() {
            const today = new Date();
            var rs = []
            for(let i = 0 ; i < 7 ; i++){
                rs[i] = today.getFullYear()+'-'+this.dateFormat((today.getMonth()+1))+'-'+this.dateFormat(today.getDate())
                today.setDate(today.getDate()-1)
            }
            return rs
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        total(){
            return Math.ceil(this.listTotal/this.memberLimit)
        },
    },
    methods: {
        // 飲食管理 取會員列表資料
        fetchDietMember(eid){
            var _self = this
            _self.$vs.loading()
            let payload = {
                "logLimit": 1,
                "memberPage": _self.memberPage,
                "memberLimit": _self.memberLimit,
                "partner_id":_self.partner_id
            }
            if(_self.query) payload.query = _self.query
            if(eid){
                payload.eid = _self.eid
            }
            fetchDietMember(payload).then(res => {
                if(res.data.total!=0){
                    _self.list = _.cloneDeep(res.data.data)
                    _self.listTotal = res.data.total[0].total
                    _.forEach(_self.list, function(v,i){
                        _self.list[i].data = _.orderBy(v.data, ['date'], ['desc'])
                    })
                    if(res.data.enterpriseList){
                        _self.enterpriseList = _.cloneDeep(res.data.enterpriseList)
                        _self.eid = _self.enterpriseList[0].id
                    }
                }else{
                    _self.listTotal=0
                    _self.list = []
                }
                _self.$vs.loading.close()
            })
        },
        // 開啟 飲食分析視窗
        openPopup(mid, date, type){
            this.payload = {
                "mid": mid,
                "date": date,
                "type": type
            }
            document.body.style.overflow = 'hidden';
            this.dietPopupActive = true
        },
        // 關閉 飲食分析視窗
        closePopup(v){
            this.dietPopupActive = v
            document.body.style.overflow = 'visible';
            this.fetchDietMember(this.eid)
        },
        // 開啟訊息視窗
        showChatPopup(mid, name){
            document.body.style.overflow = 'hidden';
            this.midChat = mid
            this.nameChat = name
            this.openChatPopup = true
        },
        // 關閉訊息視窗
        closeChatPopup(v){
            document.body.style.overflow = 'visible';
            this.midChat = 0
            this.openChatPopup = v
        },
        // 每日數值加總
        dailyTotal(am, pm, night, other){
            let total = 0
            if(!isNaN(am)) total += am
            if(!isNaN(pm)) total += pm
            if(!isNaN(night)) total += night
            if(!isNaN(other)) total += other
            return total
        },
        age(birth) {
        if (birth === '0000-00-00') {
            return '--'
        } else {
            const ageMS = Date.parse(Date()) - Date.parse(birth)
            const age = new Date()
            age.setTime(ageMS)
            const ageYear = age.getFullYear() - 1970
            return ageYear
        }
        },
        // 回傳 男/女 文字
        getSex(sex){
        if(sex==1){
            return this.$t('reservation.man')
        }else if(sex==2){
            return this.$t('reservation.woman')
        }else{
            return '-'
        }
        },
        getValue(value){
            if(value == null) return "-"
            return value
        },
        //轉換成直接撥打的電話格式
        getPhone(number){
            number = number.slice(2,10)
            number="tel:+886-9"+number
            return number
        },
        geti18n(item){
            if(this.$i18n.locale == 'tw'){
                return item.name
            }else if(this.$i18n.locale == 'cn'){
                return item.name
            }else if(this.$i18n.locale == 'en'){
                return item.ref
            }
        },
        // 檢視病歷飲食管理頁面
        viewPersonalData(data) {
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', {name: data.name, pid: data.pid,account: data.info[0].account})
            localStorage.setItem('selectedpid', JSON.stringify({name: data.name, pid: data.pid,account: data.info[0].account}))
            this.$router.push('/emr2/' + data.pid + '/0/?index=dietReport')
        },
        //開啟推薦商品視窗
        openProductPopup(pid,id,name){
            this.recommandPid =pid
            this.recommsnid = id
            this.recommpname = name
            document.body.style.overflow = 'hidden';
            this.productPopupActive = true
        },
        //關閉推薦商品視窗
        productClose(){
            document.body.style.overflow = 'visible';
            this.productPopupActive = false
        },
    },
    beforeRouteLeave(to, from, next){
        this.dietPopupActive = false
        this.openChatPopup   = false
        next();
    },
}
</script>